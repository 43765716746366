<template>
  <!-- 通知公告页面 -->
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="投诉通知" name="first" class="infinite-list">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="scrolls">
          <div class="information" v-for="(item,index) in list" :key="index">
            <img src="@/assets/img/xiaoxi_icon.png" alt="">
            <div class="content">
              <div class="content_top">
                投诉通知<span>{{item.create_time}}</span>
              </div>
              <div class="content_bottom">
                {{item.content}}
              </div>
            </div>
          </div>
        </van-list>
      </el-tab-pane>
      <el-tab-pane label="投诉回复" name="second">
        <van-list v-model="loading1" :finished="finished1" finished-text="没有更多了" @load="scrolls_one">
        <div class="information" v-for="(items,index) in list_one" :key="index" @click="Go_Details(items.userComplaints.id)">
          <img src="@/assets/img/xiaoxi_icon.png" alt="">
          <div class="content">
            <div class="content_top">
              回复通知<span>{{items.create_time}}</span>
            </div>
            <div class="content_bottom" v-if="items. msg== '评论了你'">
              《{{items.to_userinfo}}》{{items.msg}}   
              <span style="color:#E47B15;">《{{items.userComplaints.statement_title}}》</span>
            </div>
            <div class="content_bottom" v-if="items.msg == '点赞了你'">
              《{{items.nickname}}》{{items.msg}}
            </div>
          </div>
        </div>
        </van-list>
      </el-tab-pane>
      <el-tab-pane label="系统公告" name="third">
        <van-list v-model="loading2" :finished="finished2" finished-text="没有更多了" @load="scrolls_two">
        <div class="information" v-for="(item,index) in list_two" :key="index">
          <img src="@/assets/img/xiaoxi_icon.png" alt="">
          <div class="content">
            <div class="content_top">
              系统公告<span>{{item.create_time}}</span>
            </div>
            <div style="margin-top: 10px">
              标题：《 {{item.title}}》
            </div>
            <div class="content_bottom">
              {{item.content}}
            </div>
          </div>
        </div>
        </van-list>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
import { news_list, notice_list } from "../../api/api.js";
import Swiper from "swiper";
export default {
  metaInfo(){
     return{
        title: '绑定新手机号',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      activeName: "first",
      list: [], //通知列表
      list_one: [], //投诉回复列表
      list_two: [], //系统公告列表
      page: 1,
      page1: 1,
      page2: 1,
      load: true,
      loading: false,
      finished: false,
      loading1: false,
      finished1: false,
      loading2: false,
      finished2: false,
    };
  },
  methods: {
    // 去详情页面
    Go_Details(id) {
      //console.log(id);
      this.$router.push({
        path: "/PersonalCenter/CDetails",
        query: { id: id },
      });
    },

    handleClick(tab, event) {
      //console.log(tab, event);
    },

    notice_one() {
      news_list({ type: 1,page:this.page1  }).then((res) => {
        let that = this;
        //console.log(res);
        that.list_one = res.data.data.data;
      });
    },
    notice_two() {
      notice_list({}).then((res) => {
        let that = this;
        //console.log(res);
        that.list_two = res.data.data.data;
      });
    },

    //投诉通知
    scrolls() {
      news_list({ type: 2, page: this.page }).then((res) => {
        setTimeout(() => {
          this.page = this.page + 1;
          if (res.data.data.data.length > 0) {
            this.list.push(...res.data.data.data);
            this.loading = false;
          } else {
            this.finished = true;
          }
        }, 500);
      });
    },

    //回复通知
    scrolls_one() {
            news_list({ type: 1, page: this.page1 }).then((res) => {
              //console.log(res.data.data.data);
        setTimeout(() => {
          this.page1 = this.page1 + 1;
          if (res.data.data.data.length > 0) {
            this.list_one.push(...res.data.data.data);
            this.loading1 = false;
          } else {
            this.finished1 = true;
          }
        }, 500);
      });
    },
    // 系统公告
    scrolls_two() {
            notice_list({  page: this.page2 }).then((res) => {
        setTimeout(() => {
          this.page2 = this.page2 + 1;
          if (res.data.data.length > 0) {
            this.list_two.push(...res.data.data);
            this.loading2 = false;
          } else {
            this.finished2 = true;
          }
        }, 500);
      });
    },
  },
  mounted() {
    this.notice_one();
    this.notice_two();
  },
};
</script>

<style scoped>
>>> .el-tabs {
  width: 950px;
  background: #fff;
  margin-left: 20px;
  /* padding:20px; */
}
>>> .el-tabs__nav-wrap {
  padding: 20px 20px 0;
}
>>> .el-tabs__content {
  padding: 0 20px 20px;
}
.information {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}
.information img {
  width: 48px;
  height: 48px;
}
.content {
  margin-left: 20px;
}
.content_top {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
}
.content_top span {
  font-size: 12px;
  color: #999999;
  font-weight: 400;
  margin-left: 10px;
}
.content_bottom {
  font-size: 14px;
  color: #333333;
  width: 720px;
  margin-top: 10px;
}
.czp {
  width: 96%;
  height: 50px;
  line-height: 50px;
  padding-left: 35px;
}
.swiper-button-next {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  right: -2px;
  top: 23px;
  color: #1f72d9;
}
.swiper-button-prev {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  left: -3px;
  top: 23px;
  color: #1f72d9;
}
/*分页*/
.page-bar {
  margin: 40px auto;
  margin-top: 150px;
}
ul,
li {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
.page-bar li:first-child > a {
  margin-left: 0px;
}
.page-bar a {
  border: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.page-bar a:hover {
  background-color: #eee;
}
.page-bar a.banclick {
  cursor: not-allowed;
}
.page-bar .active a {
  color: #fff;
  cursor: default;
  background-color: #e96463;
  border-color: #e96463;
}
.page-bar i {
  font-style: normal;
  color: #d44950;
  margin: 0px 4px;
  font-size: 12px;
}
</style>